import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { Pre, Layout, Link, Img } from '@components';

const components = {
  pre: Pre,
  a: Link,
  img: Img,
}

const Post = ({ data }) => {
  const { title, prevPage, nextPage } = data.mdx.frontmatter
  return (
    <Layout title={title}>
      <MDXProvider components={components}>
        <MDXRenderer
          title="My Stuff!"
        >
          {data.mdx.body}
        </MDXRenderer>
      </MDXProvider>

      {(nextPage || prevPage) && (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ m: '20px 0' }}
        >
          <Button
            href={prevPage}
            variant="outlined"
            startIcon={<ArrowBackIosIcon />}
            disabled={!prevPage}
          >
            Предыдущая статья
          </Button>
          <Button
            href={nextPage}
            variant="outlined"
            endIcon={<ArrowForwardIosIcon />}
            disabled={!nextPage}
          >
            Следующая статья
          </Button>
        </Stack>
      )}
    </Layout>
  )
}

export default Post

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        nextPage
        prevPage
      }
      body
    }
  }
`
